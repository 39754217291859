























































import { CommunityModel } from '@/models/community-model'
import { ProfileModel } from '@/models/profile-model'
import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'follow-button': () => import('@/modules/common/components/follow-button.vue'),
  },
})
export default class extends Vue {
  @Prop({ default: undefined }) dao!: CommunityModel
  @Prop({ default: {} }) profile!: ProfileModel
  @Prop({ default: {} }) postStore!: PostStore
  @Prop({ default: true }) requireWallet!: boolean
  @Prop({ default: true }) requireLogin!: boolean
  @Prop({ default: '60' }) size!: any
  @Prop({ default: false }) enableBlind!: boolean
  @Prop({ default: true }) shouldOpenProfile!: boolean
  @Prop({ default: '10' }) borderRadius!: any
  @Prop({ default: true }) showFollowButton!: any

  getProfileId(profile: ProfileModel) {
    return profile.unique_id
  }

  async follow() {
    await this.postStore.followUser(this.postStore.post)
    this.$emit('syncFollowState')
  }

  async unFollow() {
    await this.postStore.unFollowUser(this.postStore.post)
    this.$emit('syncFollowState')
  }
}
